import { Button, Icons } from '@healthinal/ui';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import ContentSection from '../components/ContentSection';
import HeurekaPatient from '../components/HeurekaPatient';
import withQueryWrapper from '../components/error-handling/QueryWrapper';
import { SharePatientDialog } from '../components/share-patient-dialog/SharePatientDialog';
import { useHeurekaPatientQueryCallcenter } from '../state/api/heurekaPatientDetails';

function Patient() {
  const [showShareDialog, setShowShareDialog] = useState(false);

  const { patientId } = useParams();
  if (!patientId) {
    throw new Error('practice and patient id needs to be set');
  }
  const patientData = useHeurekaPatientQueryCallcenter(patientId);
  if (!patientData) {
    return null;
  }

  return (
    <ContentSection>
      <HeurekaPatient
        patientData={patientData}
        headerAction={
          <Button
            startDecorator={<Icons.IosShare />}
            variant={'outlined'}
            color={'neutral'}
            onClick={() => setShowShareDialog(true)}>
            An Partnerpraxis freigeben
          </Button>
        }
      />
      <SharePatientDialog patientId={patientId} open={showShareDialog} onClose={() => setShowShareDialog(false)} />
    </ContentSection>
  );
}

export default withQueryWrapper(Patient);
