import { Autocomplete, AutocompleteOption, AutocompleteProps, ListItemContent, Typography } from '@healthinal/ui';
import { useQuery } from '@tanstack/react-query';
import { CallcenterResourceService, PracticeDto } from '../../state/api/generated';
import { buttonLoadingIndicator } from '../buttonLoadingIndicator';

interface Props extends Pick<AutocompleteProps<PracticeDto, false, true, false>, 'value' | 'onChange'> {
  patientId: string;
}

export function PartnerPracticeAutocomplete({ patientId, ...autocompleteProps }: Props) {
  const { data, isPending } = usePartnerPracticesQuery(patientId);
  return (
    <Autocomplete
      {...autocompleteProps}
      options={data ?? []}
      autoHighlight
      disableClearable
      getOptionLabel={(option) => option.name}
      sx={{ minWidth: 240 }}
      placeholder="Partnerpraxis wählen"
      noOptionsText="Keine Ergebnisse"
      disabled={isPending}
      endDecorator={isPending && buttonLoadingIndicator}
      required
      renderOption={(props, option) => (
        <AutocompleteOption {...props} key={option.id}>
          <ListItemContent>
            <Typography level="body-md" textColor="inherit">
              {option.name}
            </Typography>
            <Typography level="body-sm" textColor="neutral.400" noWrap>
              {option.street}, {option.postcode} {option.city}
            </Typography>
          </ListItemContent>
        </AutocompleteOption>
      )}
    />
  );
}

const usePartnerPracticesQuery = (patientId: string) => {
  return useQuery({
    queryKey: ['callcenter', 'partnerpractices', patientId],
    queryFn: () => CallcenterResourceService.getPartnerPractices(patientId),
  });
};
