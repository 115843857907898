import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './AppCallcenter';
import Providers from './Providers';
import './index.css';
import { setupSentry } from './sentry';

setupSentry();

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <Providers>
      <App />
    </Providers>
  </React.StrictMode>,
);
