import {
  Button,
  FormControl,
  FormLabel,
  Icons,
  Modal,
  ModalClose,
  ModalDialog,
  ModalProps,
  Snackbar,
  Stack,
} from '@healthinal/ui';
import { useMutation } from '@tanstack/react-query';
import { addDays } from 'date-fns';
import { useState } from 'react';
import { CallcenterResourceService, PracticeDto } from '../../state/api/generated';
import { DurationInDays, DurationSelector } from '../DurationSelector';
import { ModalTitle } from '../ModalTitle';
import { buttonLoadingIndicator } from '../buttonLoadingIndicator';
import { PartnerPracticeAutocomplete } from './PartnerPracticeAutocomplete';

interface Props extends Omit<ModalProps, 'children'> {
  patientId: string;
  onClose: () => void;
}

const DEFAULT_DURATION: DurationInDays = 14;

export function SharePatientDialog({ patientId, onClose, ...modalProps }: Props) {
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [durationInDays, setDurationInDays] = useState<DurationInDays>(DEFAULT_DURATION);
  const [practice, setPractice] = useState<PracticeDto | undefined>(undefined);

  const closeModal = () => {
    onClose();

    // Because this component is not unmounted when the dialog is closed,
    // we have to reset the state in case the dialog is opened a second time.
    setPractice(undefined);
    setDurationInDays(DEFAULT_DURATION);
  };

  const { mutate: share, isPending } = useShareMutation(
    patientId,
    () => {
      setShowSuccessSnackbar(true);
      closeModal();
    },
    () => setShowErrorSnackbar(true),
  );

  return (
    <>
      <Modal {...modalProps} onClose={closeModal}>
        <ModalDialog>
          <ModalClose />
          <ModalTitle>Patient:in an Partnerpraxis freigeben</ModalTitle>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              share({
                practiceId: practice!.id,
                expiration: addDays(new Date(), durationInDays).toISOString(),
              });
            }}>
            <Stack direction="row" gap={2}>
              <FormControl>
                <FormLabel>Freigeben für</FormLabel>
                <PartnerPracticeAutocomplete
                  patientId={patientId}
                  value={practice}
                  onChange={(_, value) => setPractice(value)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Gültig für</FormLabel>
                <DurationSelector value={durationInDays} onChange={setDurationInDays} />
              </FormControl>
            </Stack>
            <Stack direction="row" gap={2} justifyContent="flex-end" mt={4}>
              <Button variant="plain" color={'neutral'} onClick={closeModal}>
                Abbrechen
              </Button>
              <Button type="submit" endDecorator={isPending && buttonLoadingIndicator}>
                Freigeben
              </Button>
            </Stack>
          </form>
        </ModalDialog>
      </Modal>

      <Snackbar
        color="success"
        open={showSuccessSnackbar}
        onClose={() => setShowSuccessSnackbar(false)}
        autoHideDuration={2000}
        startDecorator={<Icons.Check />}>
        Patient:in freigegeben
      </Snackbar>
      <Snackbar
        color="danger"
        open={showErrorSnackbar}
        onClose={() => setShowErrorSnackbar(false)}
        autoHideDuration={3000}
        startDecorator={<Icons.ErrorOutline />}>
        Ein Fehler ist aufgetreten – Patient:in konnte nicht freigegeben werden.
      </Snackbar>
    </>
  );
}

const useShareMutation = (patientId: string, onSuccess: () => void, onError: () => void) =>
  useMutation({
    mutationFn: ({ practiceId, expiration }: { practiceId: string; expiration: string }) =>
      CallcenterResourceService.sharePatientWithPartner(patientId, practiceId, expiration),
    onSuccess,
    onError,
  });
